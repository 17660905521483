import { useEffect, useState } from "react";
import CountUp from "../react-countUp/react-countup/count/index";
import LeftScrollBoardCopy from "./leftScrollBoardCopy";
// warehouseAddressRight
import WarehouseAddressRight from "./warehouseAddressRight";
import Monthly from "./monthly";
import "./leftModule.scss";
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export default function LeftTradeOverview(props) {
  const {
    tradeVolume,
    tradeName,
  } = props.data;
  const [upOutFlag, setUpOutFlag] = useState(false)
  const [isIndex, setIsIndex] = useState(0)
  useEffect(() => {
    console.log("===-typetabs==", props.typeTabs);
  }, [props.typeTabs]);

  const OverviewVal = {
    transit: "3760",
    inventory: "9480",
    entrust: "1840",
  };
  const categoryStorageBox = [
    {
      name: "仓库A",
      id: 0,
    }, {
      name: "仓库B",
      id: 0,
    },
    {
      name: "仓库C",
      id: 0,
    },
    {
      name: "仓库D",
      id: 0,
    },
  ]

  return (
    <div className="tj_left_data">
      <div className="tj_top_container">
        <div className="leftDataTitle"></div>
        <div className="tradeVolume">
          <div>总贸易货值(元)</div>
          <div>(货值:元)</div>
        </div>
        <div className="Trade">
          {tradeVolume.map((item, index) => {
            if (item === ",") {
              return (
                <span className="comma" key={item + index}>
                  {item}
                </span>
              );
            } else if (item === ".") {
              return (
                <span className="comma" key={item + index}>
                  {item}
                </span>
              );
            } else {
              return (
                <CountUp
                  start={0}
                  key={item + "-" + index}
                  end={Number(item)}
                  className="TradeData"
                // delay={2}
                // duration='30000'
                />
              );
            }
          })}
        </div>
        <div>
          <div className="category"> 月度运量</div>
          <div style={{ display: "flex" }} className="categoryBorder">
            <div className="categoryBorderLeft"></div>
            <div className="categoryBorderCenter"></div>
            <div className="categoryBorderRight"></div>
          </div>
        </div>
        <div className="MonthlyDataSituation">
          <Monthly />
        </div>

        <div>
          <div className="category"> 实时运单</div>
          <div style={{ display: "flex" }} className="categoryBorder">
            <div className="categoryBorderLeft"></div>
            <div className="categoryBorderCenter"></div>
            <div className="categoryBorderRight"></div>
          </div>
        </div>
        <div className="HistogramChartBoard">
          <LeftScrollBoardCopy />
        </div>
      </div>
      <div className="tj_bottom_container">
        <div className="operateSituation"></div>
        <div className="ProductInventorySituation">


          {/* {categoryStorageBox} */}
          <div  >
            <div className="categoryStorage"> {categoryStorageBox[isIndex].name}</div>
            <div style={{ display: "flex" }} className="categoryBorderStorage">
              <div className="categoryBorderLeftStorage"></div>
              <div className="categoryBorderCenterStorage"></div>
              <div className="categoryBorderRightStorage">
                <div className="downOutlinedStorage">
                  {/*  */}
                  {
                    upOutFlag ? <UpOutlined onClick={() => { setUpOutFlag(!upOutFlag) }} /> :
                      <DownOutlined onClick={() => { setUpOutFlag(!upOutFlag) }} />
                  }

                </div>
              </div>
            </div>
          </div>
          {
            upOutFlag ? <div className="ProductInventorySituationBox">
              {
                categoryStorageBox?.map((item, index) => {
                  return (
                    <div key={index}>

                      <div className="categoryStorageBoxLeft" onClick={() => {
                        setIsIndex(index)
                        setUpOutFlag(!upOutFlag)
                      }}>{item?.name}</div>


                    </div>
                  )
                })
              }
            </div> : ''
          }

          <div className="warehouseAddress">
            <div className="warehouseAddressLeft">
              <div className="warehouseAddressLeftOne">
                <div className="warehouseAddressLeftOneOne">仓库名称</div>
                <div className="warehouseAddressLeftOneTwo">内蒙古满洲里市满西公路以西环路以南</div>
              </div>
              <div className="goodsTransit">
                <div style={{ display: "flex" }}>
                  <div className="goodsTransitLeft"></div>
                  <div className="goodsTransitBoxRight">
                    <div className="goodsTransitBoxRightOne">占地面积 </div>
                    <div className="goodsTransitNumber">
                      <span className="goodsNumber">422694</span>{" "}
                      <span className="goodsUnit">(m²)</span>{" "}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="warehouseAddressRight">
              <WarehouseAddressRight />
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}
