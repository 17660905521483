import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

const Monthly = () => {

    const monthlyRef = useRef()
    var color = ['#60F4D9', '#44EBEA', '#43A9EB', '#D7D7D7', '#8CA3B6', '#A982FF', '#00BFA5'];
    var option = {
        color: ['#60F4D9', '#44EBEA', '#43A9EB', '#D7D7D7', '#8CA3B6', '#A982FF', '#00BFA5'],
        tooltip: {
            //提示框组件
            trigger: "axis",
            axisPointer: {
                type: "shadow",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
            textStyle: {
                color: "#839AAA",
                fontFamily: 'df',
                fontSize: 12,
            },
        },
        legend: {
            data: ['运量/吨'],
            itemHeight: 6, //修改icon图形大小
            itemWidth: 12,
            right: 10,
            textStyle: {                            //图例文字的样式
                color: function (params) {
                    console.log(params.dataIndex, "===--7778888--===")
                    return color[0]
                },          //图例文字颜色
                fontSize: 12,                   //图例文字大小
                fontFamily: 'df'
            },

        },
        grid: {
            top: '15%',
            left: '2%',
            right: '10%',
            bottom: '0%',
            containLabel: true,
        },

        xAxis: [
            {
                type: "category",
                data: [
                    "防城港",
                    "宁波港",
                    "连云港",
                    "黄埔港",
                    "青岛港",
                ],

                axisLabel: {
                    show: true,
                    textStyle: {
                        fontSize: 12,
                        color: '#839AAA',
                        fontFamily: 'df'
                    }
                }
            },
        ],
        yAxis: [
            {
                type: "value",
                splitNumber: 5,
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#839AAA',
                        fontSize: 12,
                        fontFamily: 'df',
                    }
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ["#fff"],
                        opacity: 0.06,
                    },
                },
            },
        ],
        // series: seriesData,
        series: [{
            name: '运量/吨',
            type: 'bar',
            data: [2010, 1005, 1340, 670, 1675,],

            barWidth: '10%',
            itemStyle: {
                normal: {
                    color: function (params) {
                        return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
                    }
                }
            },
        },
        ]

    };

    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(monthlyRef.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [])
    return <div style={{ width: "100%", height: "100%" }} ref={monthlyRef}></div>;
}



export default Monthly;