import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

const colors= [
    "#534EE1",
    "#ECD64F",
    "#00E4F0",
    "#44D16D",
    "#124E91",
    "#BDC414",
    "#C8CCA5",
];
export default function columnarChart({rateZoom}) {
 var variety=[
        {title:"西宁仓库",ratio:20},
        {title:"连云港",ratio:30},
        {title:"防城港",ratio:40},
    ];
    const chartRef = useRef();
    variety.length>5?variety.length=5:'';
    const options = {
        
        grid: {
            left: "2%",
            top: "4%",
            right: "0%",
            bottom: "15%",
        },
        legend: {
            selectedMode:false,
            top: "86%",
            icon: "circle",
            // type: 'scroll',  
            orient: 'horizontal', 
           left: "4%",
            itemGap: 8*rateZoom,
            itemWidth: 10*rateZoom,
            itemHeight: 10*rateZoom,
            textStyle: {
                color: "#fff",
                // left:0,
                fontSize: 12*rateZoom,

            },
            data: variety?.map(m=>m?.title.slice(0)),
        },
        color:colors,
        tooltip: {
            position: "top",
        },
        xAxis: {
            type: "category",
            data: variety?.map(m=>m?.title),
            axisLabel: {
                show: false,
                color: "#999999",
                fontSize: 16*rateZoom,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            max:120,
            axisLabel: {
                show: false,
                color: "#999999",
                fontSize: 12*rateZoom,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        series: [
           
            ...variety?.map((m,i)=>({
                name: m?.title.slice(0),
                data: [0, 0, 0, 0, 0, 0],
                stack: "a",
                type: "bar",
            })) ,
       
            {
                type: "pictorialBar",
                name: "货物类型占比",
                data: variety?.map((m,i)=>(
                    {
                        name: m?.title,
                        value: m?.ratio,
                        label: {
                            show: true,
                            position: "top",
                            formatter: function ({value}) {
                                var str = "{a|" + '_' +'%'+ "}";
                                return str;
                            },
                            rich: {
                                a: {
                                    fontSize: 12*rateZoom,
                                    color: "#fff",
                                    align: "center",
                                    height: 20,
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: colors[i],
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(83,78,225,0)",
                                        },
                                    ],
                                    global: false,
                                },
                            },
                        },
                    }
                )),
                
                stack: "a",
                symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            },
        ],
    }

    useEffect(() => {
        let timer;
        const chart = echarts.init(chartRef.current)
        let option = echarts.init(chartRef.current).getOption();
        console.log(option, "optionoption")
        // option.series[2].startAngle = option.series[2].startAngle - 1;
        // option.series[3].startAngle = option.series[3].startAngle - 1;
        // option.series[6].data[0].value = option.series[6].data[0].value + 1;





        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例

        echarts.init(chartRef.current).setOption(options);
        // timer = setInterval(doing, 100);
        // 设置图表实例的配置项和数据
        // chart.setOption(options, true)
        chart.on('mouseover', function (params) {
            // stopTimer();
            timer && clearInterval(timer);

        });
        chart.on('mouseout', function (params) {
            // startTimer();
            // timer = setInterval(doing, 100);
        });

        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            timer && clearInterval(timer);
            chart.dispose()

        }
    }, [])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>
    );
}
