
import './rightInventoryOverview.scss';
import { BorderBox1, ScrollBoard } from "@/utils/@jiaminghi/data-view-react"
import "@/index.scss";
const config1 = {
    header: ['货物品名', '港口/堆场', '信用证到期日', '浮动盈亏', '操作'],
    data: [
        ['铜矿(EGLV751231251417)', '连云港', '02-19<span style="color:#F5212D;"> 即将逾期</span>', '<span style="color:#F5212D;">+24000</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅锌矿(MEDUBG122326) ', '远港上合', '03-13<span style="color:#F5212D;">即将逾期</span>', '<span style="color:#F5212D;">+24000</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['银矿(MEDUBG1231112)', '防城港', '--', '<span style="color:#50BA58;">-1500</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅锌矿(HDMUDALA4233900)', '防城港', '--', '<span style="color:#50BA58;">-39320</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅精矿(W231233226)', '黄埔港', '04-26', '<span style="color:#F5212D;">+24040</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅锌矿(MEDUL9231223)', '黄埔港', '04-17', '<span style="color:#50BA58;">-22300</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['银矿(MEDUL9123122)', '黄埔港', '--', '<span style="color:#F5212D;">+24200</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅锌矿(MAERSK ALTAIR)', '青岛港', '05-19', '<span style="color:#50BA58;">-1200</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅精矿(EGLV142299991948)', '宁波港', '--', '<span style="color:#F5212D;">+19000</span>', '<span style="color:#00EFEC;">查看监控</span>'],
        ['铅精矿(MEDUE3232232)', '宁波港', '--', '<span style="color:#F5212D;">+1400</span>', '<span style="color:#00EFEC;">查看监控</span>'],
    ],
    // index: true,///<span style="color:#e7bcf3;">行10列2</span>
    columnWidth: [160, 90, 130, 90, 95],
    // align: ['center', 'center', 'center', 'center', 'center'],
    // carousel: 'page'
    headerBGC: 'rgba(0, 123, 162, 0)',
    headerHeight: '40',
    oddRowBGC: 'rgba(0, 123, 162, 0)',
    evenRowBGC: 'rgba(0, 123, 162, 0)',
    hoverPause: true
}
const config = {
    header: ['货物品名', '港口', '到港日', '状态'],
    data: [
        ['铅锌矿(EGLV752312351417)', '连云港', '03-12', '<span style="color:#50BA58;">开航</span>'],
        ['铅锌矿(MEDUBG2212262)', '连云港', '02-13', '<span style="color:#50BA58;">开航</span>'],
        ['铅精矿(HDMUDALA4888700)', '防城港', '01-14', '<span style="color:#50BA58;">中转</span>'],
        ['铅精矿(HDMUDALA4888700)', '防城港', '02-25', '<span style="color:#F5212D;">开航延误1天</span>'],
        ['铅锌矿(W5648776226)', '黄埔港', '03-26', '<span style="color:#50BA58;">卸货</span>'],
        ['铜矿(MEDUL4856845)', '黄埔港', '03-17', '<span style="color:#50BA58;">提货</span>'],
        ['铜矿砂及精矿(MEDUL9764563)', '黄埔港', '03-11', '<span style="color:#50BA58;">进场</span>'],
        ['铅锌矿(MAERSK ALTAIR)', '青岛港', '04-19', '<span style="color:#50BA58">提箱</span>'],
        ['铜矿(EGLV1424444544948)', '宁波港', '02-20', '<span style="color:#F5212D;">中转延误2天</span>'],
        ['铅精矿(MEDUE32527432)', '宁波港', '01-21', '<span style="color:#50BA58;">开航</span>'],
    ],
    // index: true,///<span style="color:#e7bcf3;">行10列2</span>
    // columnWidth: [100],
    // align: ['center', 'center', 'center', 'center'],
    // carousel: 'page'
    columnWidth: [290, 140, 140, 130],
    headerBGC: 'rgba(0, 123, 162, 0)',
    headerHeight: '40',
    oddRowBGC: 'rgba(0, 123, 162, 0)',
    evenRowBGC: 'rgba(0, 123, 162, 0)',
    rowNum :3,
}
function Index() {

    // useEffect(() => {


 
    return (
        <div style={{ width: '100%' }}>
            <ScrollBoard config={config} style={{ width: '100%', }} oddRowBGC='#007ba233' />
          
        </div>
    );
}
export default Index