import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function warehouseAddressRight() {
    const colors = [
        "#534EE1",
        "#ECD64F",
        "#00E4F0",
        "#44D16D",
        "#124E91",
        "#BDC414",
        "#C8CCA5",
    ];
    const chartRef2 = useRef()
    var total = 0;
  
    var option = {
        tooltip: {
            show: true,
        },
        legend: {
            right: '5%',
            top: '10%',
            orient: 'vertical',
            itemGap: 20,
            itemWidth: 15,
            itemHeight: 15,
            textStyle: {
                color: '#fff',
                fontSize: 14,
            },
            formatter: function (name) {
                let than = option.series[0].data //获取series中的data
                let total = 0
                let tarValue
              
                for (let i = 0, l = than.length; i < l; i++) {
                    total += than[i].value
                    if (than[i].name == name) {
                        tarValue = than[i].value
                    }
                }
                console.log(tarValue, "===----777777777",)
                // let p = (tarValue / total) * 100
                return name + ' ' + ' ' + ' ' + ' ' + tarValue
                // return ' ' + ' ' + ' ' + name + ' ' + ' ' + ' ' + ' ' + ' ' + tarValue + '次' + ' ' + ' ' + ' ' + p.toFixed(2) + '%'
            },
        },
        series: [
            {
                type: 'pie',
                radius: '100%',
                center: ['22%', '50%'],
                itemStyle: {
                    normal: {
                        borderWidth: 5,
                        borderColor: '#003359',
                    },
                },
                label: {
                    show: false,
                    position: 'inside',
                    align: 'center',
                    verticalAlign: 'middle',
                    formatter(params) {
                        console.log('label params', params);
                        // return params.value;
                        // return `{name|${params.name}}\n {value|${params.value}}`;
                    },
                    rich: {
                        // name: {
                        //     fontSize: 20,
                        // },
                        // value: {
                        //     fontSize: 30,
                        //     lineHeight: 40
                        // },
                    },
                },
                data: [
                    {
                        name: '低',
                        value: 57,
                    },
                    {
                        name: '中',
                        value: 103,
                    },
                    {
                        name: '高',
                        value: 147,
                    },
                ],
                labelLine: {
                    show: false,
                },
            },
        ],

    };






    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef2.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef2}></div>
    );
}
